import React, { useContext } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";

import { getSprinkles } from "~styles/getSprinkles.css";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";
import { getTopicLabels } from "~utils/storyblok_relations/translateTopics/translateTopics";

import { PageQueryContext } from "~context/PageQueryContext";

import { FeaturedCard } from "./variants/FeaturedCard";
import { GenericCard } from "./variants/GenericCard";
import { PeopleCard } from "./variants/PeopleCard";
import { ReportCard } from "./variants/ReportCard";

import type {
  VariantCardAppearanceEnum,
  VariantFeaturedContentTypeEnum,
} from "./styles.css";
import type { CardCtaProps } from "./subComponents/CardCtaText";
import type { CardIconProps } from "./subComponents/CardIcon";
import type { CardInfoProps } from "./subComponents/CardInfo";
import type { CardLogosListProps } from "./subComponents/CardLogosList";
import type { CardTitleProps } from "./subComponents/CardTitle";
import type { VariantCardProps } from "./types";
import type { ButtonLinkProps } from "~components/ButtonLink";
import type { LinkProps } from "~components/Link";
import type { SvgLogoProps } from "~components/SvgLogo";
import type { VariantBackgroundColorEnumNew } from "~styles/common/variant.backgroundColor.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { VariantThemeEnum } from "~styles/themes/variantThemeColorPalette.css";
import type {
  StoryblokBlok,
  StoryblokFieldLink,
  StoryblokFieldMedia,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

export type CardSubComponent =
  | "date"
  | "location"
  | "image"
  | "icon"
  | "logo"
  | "logosList"
  | "ctaText"
  | "description"
  | "title"
  | "prefix";

export interface CardProps
  extends Omit<LinkProps, "children" | "prefix" | "title">,
    Omit<GetSprinklesArgs, "children" | "color">,
    StoryblokBlok {
  appearance?: VariantCardAppearanceEnum;
  brandIcon?: CardIconProps["brandIcon"];
  // children?: ReactNode;
  ctaText?: CardCtaProps["ctaText"];
  date?: CardInfoProps["date"];
  description?: string | StoryblokFieldRichText;
  image?: StoryblokFieldMedia | null;
  link?: StoryblokFieldLink;
  linkToExternalPage?: StoryblokFieldLink | null;
  // linkText?: string;
  location?: CardInfoProps["location"];
  logo?: SvgLogoProps["svgLogo"] | null;
  logos?: CardLogosListProps["logos"];
  // metadata?: Array<string>;
  prefix?: string;
  subComponents?: Array<CardSubComponent>;
  theme?: VariantThemeEnum | null;
  title?: CardTitleProps["title"];
  // background?: VariantBackgroundColorEnum;
  ctaIcon?: CardCtaProps["ctaIcon"];
  featuredContentType?: VariantFeaturedContentTypeEnum | "";
  featuredCta?: Array<StoryblokBlok & ButtonLinkProps>;
  cardColor?: VariantBackgroundColorEnumNew | null;
  topics?: Array<string> | null;

  // eslint-disable-next-line react/no-unused-prop-types
  cover?: StoryblokFieldMedia | null;
}
type CardPropsValues = CardProps[keyof CardProps];

const DEFAULT_SUB_COMPONENTS: Array<CardSubComponent> = [
  "date",
  "location",
  "image",
  "icon",
  "logo",
  "logosList",
  "ctaText",
  "description",
  "title",
  "prefix",
];

const shouldRenderSubcomponents = (
  {
    date,
    location,
    image,
    brandIcon,
    title,
    description,
    logo,
    prefix,
    logos,
    ctaText,
  }: Omit<CardProps, "featuredContentType">,
  subComponents: CardProps["subComponents"] = DEFAULT_SUB_COMPONENTS
) => {
  const shouldRender = (
    component: CardPropsValues,
    componentName: (typeof DEFAULT_SUB_COMPONENTS)[number]
  ) => {
    return Boolean(component && subComponents?.includes(componentName));
  };

  const shouldRenderDate = shouldRender(date, "date");
  const shouldRenderLocation = shouldRender(location, "location");
  const shouldRenderImage = Boolean(
    image?.filename && subComponents.includes("image")
  );
  const shouldRenderIcon = shouldRender(brandIcon, "icon");
  const shouldRenderTitle = shouldRender(title, "title");
  const shouldRenderDescription = shouldRender(description, "description");
  const shouldRenderLogo = shouldRender(logo, "logo");
  const shouldRenderPrefix = shouldRender(prefix, "prefix");
  const shouldRenderLogosList =
    checkHasLength(logos) && shouldRender(logos, "logosList");
  const shouldRenderCta = shouldRender(ctaText, "ctaText");

  return {
    shouldRenderDate,
    shouldRenderLocation,
    shouldRenderImage,
    shouldRenderIcon,
    shouldRenderTitle,
    shouldRenderDescription,
    shouldRenderLogo,
    shouldRenderPrefix,
    shouldRenderLogosList,
    shouldRenderCta,
  };
};

/** Renders a styled card that can be passed a link. */
export function Card({
  appearance = "generic",
  brandIcon,
  className: userClassName,
  ctaText,
  date,
  description,
  image,
  link,
  linkToExternalPage,
  location,
  logo,
  logos,
  prefix,
  subComponents = DEFAULT_SUB_COMPONENTS,
  title,
  ctaIcon,
  featuredContentType,
  featuredCta: featuredCtaBlokArray,
  cardColor,
  topics,

  // TODO: remove this prop
  theme: _theme,

  ...rest
}: CardProps) {
  const { pageContext } = useContext(PageQueryContext);
  const { topics: contextTopics } = pageContext || {};
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);
  const [featuredCta] = featuredCtaBlokArray || [];

  const {
    shouldRenderDate,
    shouldRenderLocation,
    shouldRenderImage,
    shouldRenderIcon,
    shouldRenderTitle,
    shouldRenderDescription,
    shouldRenderLogo,
    shouldRenderPrefix,
    shouldRenderLogosList,
    shouldRenderCta,
  } = shouldRenderSubcomponents(
    {
      date,
      location,
      image,
      brandIcon,
      title,
      description,
      logo,
      prefix,
      logos,
      ctaText,
      topics,
    },
    subComponents
  );

  const [primaryTopic] = getTopicLabels(topics, contextTopics) || [];

  const variantProps: VariantCardProps = {
    atomProps,
    otherProps,
    image: shouldRenderImage ? image : null,
    title: shouldRenderTitle ? title : null,
    description: shouldRenderDescription ? description : null,
    logo: shouldRenderLogo ? logo : null,
    date: shouldRenderDate ? date : null,
    location: shouldRenderLocation ? location : null,
    brandIcon: shouldRenderIcon ? brandIcon : null,
    prefix: shouldRenderPrefix ? prefix : null,
    ctaText: shouldRenderCta ? ctaText : null,
    ctaIcon,
    logos: shouldRenderLogosList ? logos : null,
    featuredContentType: featuredContentType || "blog",
    featuredCta,
    appearance,
    link: linkToExternalPage || link,
    cardColor,
    primaryTopic,
    userClassName,
  };

  // TODO: Complete with other appearances
  switch (appearance) {
    case "people":
      return <PeopleCard {...variantProps} />;
    case "featured":
      return <FeaturedCard {...variantProps} />;
    case "report":
      return <ReportCard {...variantProps} />;
    default:
      return <GenericCard {...variantProps} />;
  }
}
