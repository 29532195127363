import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { Link } from "~components/Link";
import { Tag } from "~components/Tag";

import * as styles from "../styles.css";
import { CardCta } from "../subComponents/CardCtaText";
import { CardDescription } from "../subComponents/CardDescription";
import { CardIcon } from "../subComponents/CardIcon";
import { CardImage } from "../subComponents/CardImage";
import { CardInfo } from "../subComponents/CardInfo";
import { CardLogo } from "../subComponents/CardLogo";
import { CardLogosList } from "../subComponents/CardLogosList";
import { CardPrefix } from "../subComponents/CardPrefix";
import { CardTitle } from "../subComponents/CardTitle";
import { SvgCardGridBackground } from "../subComponents/SVGCardGridBackground";

import type { VariantCardBackgroundEnum } from "../styles.css";
import type { VariantCardProps } from "../types";
import type { LinkProps } from "~components/Link";

export function ReportCard({
  userClassName,
  atomProps,
  otherProps,
  image,
  cardColor,
  appearance,
  featuredContentType,
  primaryTopic,
  link,
  date,
  location,
  logos,
  ctaText,
  ctaIcon,
  prefix,
  title,
  description,
  logo,
  brandIcon,
}: VariantCardProps) {
  const reportBackgroundColorKey = cardColor || "background_lavender";

  const reportBorder = !image ? reportBackgroundColorKey : "none";

  const reportColor = variantBackgroundColor[reportBackgroundColorKey];

  const cardWrapperClassNames = clsx(
    userClassName,
    getSprinkles({
      ...atomProps,
      border: reportBorder,
    }),
    styles.getCardStyles({
      appearance,
      featuredContentType,
      padding: !image,
    }),
    !image ? reportColor : null
  );

  const linkProps: LinkProps = {
    fallbackElement: "div",
    link,
    className: cardWrapperClassNames,
    ...otherProps,
  };

  return (
    <Link {...linkProps}>
      {/* Image */}
      {image ? (
        <CardImage
          boxClassName={styles.imageOuter}
          position="relative"
          height="100%"
          width="auto"
          image={image}
          imageClassName={styles.imageInner}
          objectFit="cover"
        />
      ) : (
        <SvgCardGridBackground backgroundColor={reportBackgroundColorKey} />
      )}

      {/* Logo */}
      {logo && !image && <CardLogo logo={logo!} />}

      {/* Brand icon */}
      {brandIcon && !image && <CardIcon brandIcon={brandIcon} />}

      {/* Topic tag */}
      {primaryTopic && (
        <Tag
          tagSize="sm"
          title={primaryTopic}
          className={styles.getTopicTagStyle({
            cardBackground: image
              ? "background_charcoal"
              : (reportBackgroundColorKey as VariantCardBackgroundEnum),
          })}
        />
      )}

      {!image && (
        <div className={styles.cardInner}>
          <div className={styles.cardHeader}>
            {/* Date & location */}
            {(date || location) && <CardInfo date={date} location={location} />}

            {/* Prefix & title */}
            {prefix && <CardPrefix {...{ prefix }} />}

            {/* Title */}
            {title && !image && <CardTitle title={title!} />}
          </div>

          {/* Description */}
          {description && <CardDescription text={description} />}

          {/* Logos */}
          {logos && <CardLogosList {...{ logos }} />}

          {/* Cta text */}
          {ctaText && <CardCta {...{ ctaIcon, ctaText }} />}
        </div>
      )}
    </Link>
  );
}
